import { useEffect, useMemo } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'
import { DateTime, Duration } from 'luxon'

import { useIntl } from 'react-intl'
import { AssetTrackingData, useAssetTelemetry } from '@/feature/telemetry'

import { SHORT_DATE_FORMAT } from '@/lib/core/constants/constants'
import { DatePicker, DatePickerTrigger } from '@/lib/core/components/DatePicker'
import { PlusCircleIcon } from '@/lib/core/components/Icon'
import { Input } from '@/lib/core/components/TextField/TextField.styles'
import { RadioButtons } from '@/lib/core/components/RadioButtons'
import { SelectField, SelectOption } from '@/lib/core/components/Select'

import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import {
  SelectAdorment,
  ShareFormState,
} from '@/lib/features/SmartShares/components/ShareAssetModal'
import {
  CellDuration,
  DurationTypeTd,
  Error,
  Remove,
  Td,
} from '@/lib/features/SmartShares/components/ShareAssetModal/components/AssetsTable'
import { Share } from '@/lib/features/SmartShares/interfaces'
import { Asset } from '@/lib/features/Assets/interfaces'

export type ShareRowProps = {
  items: Array<SelectOption>
  index: number
  isLoading?: boolean
  onAddNewAsset?: () => void
  onRemove?: () => void
  shareToExtend?: Share
  rows: number
  uid: string
}

const isValidVehicle = (tracking: AssetTrackingData | undefined) => {
  return !(
    tracking?.sourceDatetime == null ||
    DateTime.fromJSDate(tracking?.sourceDatetime).diffNow() < Duration.fromObject({ hours: -24 })
  )
}

export const ShareRow = ({
  shareToExtend,
  items,
  index,
  isLoading,
  onAddNewAsset,
  onRemove = () => null,
  rows,
  uid,
}: ShareRowProps) => {
  const { formatMessage } = useIntl()
  const { clearErrors, control, formState, setValue, watch, setError, getValues } =
    useFormContext<ShareFormState>()
  const currentValue = watch(`assets.${index}`)
  const organizationTo = watch('organizationTo')
  const selectedAssets = watch('assets')
  const defaultValue = useMemo(
    () => items.find(({ value }) => currentValue.asset === value),
    [items, currentValue],
  )

  const options = useMemo(() => {
    const values = getValues()
    const { organizationTo } = values
    const filtered = items.filter((item) => !selectedAssets?.find((sa) => sa.asset === item.value))

    return filtered.map((item) => {
      if (item.data && organizationTo) {
        const assetData = item.data.asset as Asset
        item.data.isOwnedFromOrganizationTo = organizationTo === assetData?.owner.id
      }
      return item
    })
  }, [selectedAssets, organizationTo])

  const assetsTrackData = useAssetTelemetry()

  const currentAssetData = useMemo(() => {
    const item = items.find(({ value }) => currentValue.asset === value)
    if (item?.data?.asset) {
      return item.data.asset as Asset
    }
    return null
  }, [currentValue.asset])

  useEffect(() => {
    const item = items.find(({ value }) => currentValue.asset === value)

    if (item?.data?.id == undefined) return
    const tracking = assetsTrackData.get(item?.data?.id as string)
    if (!isValidVehicle(tracking)) {
      setError(`assets.${index}.asset`, {
        type: 'validate',
        message: 'Vehicle is currently offline and cannot be shared',
      })
    } else {
      clearErrors(`assets.${index}.asset`)
    }
  }, [currentValue.asset, currentValue, assetsTrackData, setError, items, index])

  useEffect(() => {
    if (shareToExtend) {
      setValue(`assets.${index}.asset`, shareToExtend.assetId)
      setValue(`assets.${index}.reference`, shareToExtend.reference)
      setValue(`assets.${index}.durationType`, 'custom')
      setValue(`assets.${index}.date`, {
        to: shareToExtend.dateTo,
        from: shareToExtend.dateFrom,
      })
    }
  }, [shareToExtend])

  useEffect(() => {
    if (currentAssetData) {
      setValue(`assets.${index}.ownerId`, currentAssetData.owner.id)
    }
  }, [currentAssetData])

  const { errors, isSubmitting } = formState

  const durationOptions = useMemo(
    () => [
      {
        label: formatMessage(messages.durationCustom),
        value: 'custom',
      },
      {
        label: formatMessage(messages.durationInstant),
        value: 'instant',
      },
    ],
    [],
  )

  return (
    <tr>
      <Td>
        {isLoading ? (
          <Input
            name={`assets.${index}.asset.placeholder`}
            disabled
            placeholder={formatMessage(messages.loading)}
          />
        ) : (
          <SelectField
            aria-labelledby={`assets.${index}.asset`}
            name={`assets.${index}.asset`}
            placeholder={formatMessage(messages.assetSelectPlaceholder)}
            leftAdorment={
              <SelectAdorment $disabled={!!shareToExtend}>{`#${index + 1}`}</SelectAdorment>
            }
            disabled={isSubmitting}
            displayInline
            required
            selectProps={{
              defaultValue,
              isDisabled: !!shareToExtend ?? isSubmitting,
              maxMenuHeight: 200,
              options: options,
              // @ts-expect-error Passing type info forward is not supported
              isOptionDisabled(
                option: SelectOption<
                  string | number,
                  { id: string; isOwnedFromOrganizationTo?: boolean }
                >,
              ) {
                if (option?.data?.id == undefined) return false
                if (option?.data?.isOwnedFromOrganizationTo) {
                  return true
                }

                const tracking = assetsTrackData.get(option?.data?.id)
                return !isValidVehicle(tracking)
              },
              // ...(shareToExtend
              //   ? null
              //   : {
              //       actionCallback: onAddNewAsset,
              //       actionOption: (
              //         <>
              //           <PlusCircleIcon size="24" />
              //           <FormattedMessage {...messages.assetSelectAddNew} />
              //         </>
              //       ),
              //     }),
            }}
          />
        )}
      </Td>
      <Td>
        <Input
          name={`assets.${index}.reference`}
          placeholder="077/01"
          disabled={!!shareToExtend}
          value={currentValue.reference}
          style={{ minWidth: 140 }}
          onChange={(value) => {
            setValue(`assets.${index}.reference`, value.target.value)
          }}
        />
      </Td>
      <DurationTypeTd>
        <Controller
          control={control}
          name={`assets.${index}.durationType`}
          render={({ field }) => (
            <RadioButtons
              name={`duration-type-${uid}`}
              currentValue={shareToExtend ? 'custom' : field.value}
              onChange={(value) => {
                field.onChange(value)
                setValue(`assets.${index}.date`, null)
                clearErrors(`assets.${index}.date`)
              }}
              options={durationOptions}
            />
          )}
        />
      </DurationTypeTd>
      <Td>
        <CellDuration>
          {currentValue?.durationType === 'custom' ? (
            <Controller
              control={control}
              name={`assets.${index}.date`}
              render={({ field }) => (
                <DatePicker
                  initialDate={{
                    from: field.value?.from,
                    to: field.value?.to,
                  }}
                  mode="range"
                  onChange={field.onChange}
                  dateSearchFilter={null}
                  placeholderFrom="Select Start"
                  placeholderTo="Select End"
                  hasError={!!errors?.assets?.[index]?.date}
                  disabled={[{ before: new Date() }]}
                />
              )}
            />
          ) : (
            <DatePickerTrigger disabled type="button">
              {dayjs().format(SHORT_DATE_FORMAT)}
            </DatePickerTrigger>
          )}
        </CellDuration>
        {errors?.assets?.[index]?.date && (
          <Error>{(errors.assets[index]?.date as FieldError).message}</Error>
        )}
      </Td>
      <Td $center>
        <Remove type="button" disabled={rows < 2} onClick={onRemove}>
          <PlusCircleIcon size="20" />
        </Remove>
      </Td>
    </tr>
  )
}
