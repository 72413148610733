import styled, { css } from 'styled-components'

export type LabelProps = {
  $disabled?: boolean
  $displayInline?: boolean
  $required?: boolean
}

export const Label = styled.label<LabelProps>`
  display: block;
  line-height: 2;
  cursor: pointer;
  ${({ $displayInline }) =>
    !$displayInline &&
    css`
      margin-bottom: ${({ theme: { layout } }) => layout.spacing(1)};
    `}
  ${({ $required }) =>
    $required &&
    css`
      &::after {
        content: ' *';
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme: { colors } }) => colors.disabled};
    `}
`

export const Container = styled.div`
  width: 100%;
  //min-height: 580px;
  padding: ${({ theme: { layout } }) => `${layout.spacing(5)} ${layout.spacing(6)}`};

  ${Label} {
    font-weight: 600;
    font-size: 1.125rem;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  min-height: ${({ theme: { layout } }) => layout.spacing(11)};
  margin-top: ${({ theme: { layout } }) => layout.spacing(2)};
`
