import { ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from '@/lib/core/components/Button'
import { CheckCircleIcon } from '@/lib/core/components/Icon'
import { useCurrency } from '@/lib/core/hooks/useCurrency'
import { SimpleModal, SimpleModalProps } from '@/lib/core/components/Modal'
import { Table, TableColumns, Td, Tr } from '@/lib/core/components/Table'

import { BridgeId } from '@/lib/features/Assets/interfaces'

import messages from './SharesSummaryModal.messages'
import {
  Container,
  SubTitle,
  TableWrapper,
} from './SharesSummaryModal.styles'

type Shares = {
  assetId: BridgeId
  description: string
  reference: string
  dates: string
  days: number
  cost: string
}

export type SharesSummaryModalProps = Pick<
  SimpleModalProps,
  'title' | 'headingIcon' | 'isActive'
> & {
  isLoading?: boolean
  onClose?: () => void
  shares: Array<Shares>
  subtitle?: string | ReactNode
  totalCost: { [key: string]: number }
}

export const SharesSummaryModal = ({
  headingIcon,
  isActive,
  isLoading,
  onClose = () => null,
  shares,
  subtitle,
  title,
  totalCost,
}: SharesSummaryModalProps) => {
  const { formatMessage } = useIntl()
  const { formatAmount } = useCurrency()
  const { colors } = useTheme()

  const columns = useMemo<TableColumns>(
    () => [
      {
        accessor: 'assetId',
        label: formatMessage(messages.assetId),
      },
      {
        accessor: 'description',
        label: formatMessage(messages.description),
      },
      {
        accessor: 'reference',
        label: formatMessage(messages.reference),
      },
      {
        accessor: 'dates',
        label: formatMessage(messages.dates),
      },
      {
        accessor: 'days',
        label: formatMessage(messages.days),
      },
      // {
      //   accessor: 'cost',
      //   label: formatMessage(messages.cost),
      // },
    ],
    [],
  )

  return (
    <SimpleModal
      isActive={isActive}
      actionsAlignItems="flex-start"
      headingIcon={headingIcon || <CheckCircleIcon size="48" color={colors.featureShares} />}
      title={title}
      actions={
        <Button fitToContent minWidth size="large" variant="secondary" onClick={onClose}>
          <FormattedMessage {...messages.done} />
        </Button>
      }
    >
      <Container>
        <SubTitle>{subtitle}</SubTitle>
        <TableWrapper>
          <Table isLoading={isLoading} columns={columns} hideHeader stickyLoader={false}>
            {shares.map(({ assetId, cost, dates, days, description, reference }) => (
              <Tr key={`${assetId}-${dates}`}>
                <Td>{assetId}</Td>
                <Td>{description}</Td>
                <Td>{reference}</Td>
                <Td>{dates}</Td>
                <Td>{days}</Td>
                {/* <Td>{cost}</Td> */}
              </Tr>
            ))}
          </Table>
        </TableWrapper>
      </Container>
    </SimpleModal>
  )
}
