import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from '@/lib/themes'

import { useAppDispatch } from '@/lib/core/store/store'
import { GET_ALL_LIMIT } from '@/lib/core/constants/constants'
import { SelectField, SelectOption } from '@/lib/core/components/Select'
import { ChevronDownIcon, PlusCircleIcon } from '@/lib/core/components/Icon'
import { Colors } from '@/lib/core/components/Select/Select.styles'

import { searchMyContacts, searchMyContactsSelector } from '@/lib/features/Contacts/store'
import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import { Share } from '@/lib/features/SmartShares/interfaces'

import {
  Container,
  ExtendSelectContactItem,
  ExtendSelectContactLabel,
} from './SelectContact.styles'
import { ShareFormState } from '../../ShareAssetModal'

export type SelectContactProps = {
  onAddNewContact?: () => void
  shareToExtend?: Share
}

export const SelectContact = ({
  onAddNewContact = () => null,
  shareToExtend,
}: SelectContactProps) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const myContacts = useSelector(searchMyContactsSelector)
  const { formState, setValue, watch } = useFormContext<ShareFormState>()
  const selectedAssets = watch('assets')

  const options = useMemo(
    () =>
      myContacts.data.results
        .map(
          ({ organizationTo: { id, name } }) =>
            ({
              label: name,
              value: id,
              data: {
                optionType: 'SelectContact',
              },
            }) as SelectOption,
        )
        .sort((a, b) =>
          a.label.localeCompare(b.label, 'en', { caseFirst: 'upper', numeric: true }),
        ),
    [myContacts],
  )

  useEffect(() => {
    if (shareToExtend) {
      setValue('organizationTo', shareToExtend.organization.id)
    }
  }, [shareToExtend])

  useEffect(() => {
    dispatch(searchMyContacts({ pageSize: GET_ALL_LIMIT }))
  }, [])

  return (
    <Container>
      {shareToExtend ? (
        <div>
          <ExtendSelectContactLabel>Who are you sharing with *</ExtendSelectContactLabel>
          <ExtendSelectContactItem>
            {shareToExtend.organization.name}{' '}
            <ChevronDownIcon size={'24'} color={(theme.colors as Colors).primary} />
          </ExtendSelectContactItem>
        </div>
      ) : (
        <SelectField
          aria-labelledby="organizationTo"
          name="organizationTo"
          label={formatMessage(messages.organizationToLabel)}
          placeholder={formatMessage(messages.organizationToPlaceholder)}
          disabled={shareToExtend ? true : formState.isSubmitting}
          required
          selectProps={{
            actionCallback: onAddNewContact,
            actionOption: (
              <>
                <PlusCircleIcon size="24" />
                <FormattedMessage {...messages.organizationToAddNew} />
              </>
            ),
            isDisabled: shareToExtend ? true : formState.isSubmitting,
            options,
            // @ts-expect-error Passing type info forward is not supported
            isOptionDisabled(option: SelectOption<string | number, { id: string }>) {
              return selectedAssets.find((sa) => sa.ownerId === option.value)
            },
          }}
        />
      )}
    </Container>
  )
}
