import styled, { css } from 'styled-components'

import { Td, Th } from '@/lib/core/components/Table'

export const Container = styled.div`
  display: block;
`

export const SubTitle = styled.p`
  margin: 0;
  line-height: 1;
  text-align: center;
`

const cellHeightStyles = css`
  height: ${({ theme: { layout } }) => layout.spacing(7)};
`

export const TableWrapper = styled.div`
  min-width: 848px;
  margin: ${({ theme: { layout } }) => `${layout.spacing(7)} 0 ${layout.spacing(2)}`};

  ${Th} {
    font-weight: 700;
    ${cellHeightStyles}
  }

  ${Td} {
    ${cellHeightStyles}
  }
`

export const TableSummary = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`

export const Total = styled.p`
  display: flex;
  align-items: baseline;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  min-height: ${({ theme: { layout } }) => layout.spacing(7)};
  margin: 0;
  padding: ${({ theme: { layout } }) => layout.spacing(2)};
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
`

export const TotalCurrencies = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: ${({ theme: { fontSize } }) => fontSize.large};
`
