import { ReactNode } from 'react'

import { MergeElementProps } from '@/lib/core/interfaces/common'

import { StyledErrorMessage } from './ErrorMessage.styles'

export type ErrorMessageProps = MergeElementProps<
  'div',
  { [k: string]: unknown; children: ReactNode | Array<ReactNode> }
>

export const ErrorMessageComponent = ({ children, ...props }: ErrorMessageProps) => (
  <StyledErrorMessage {...props}>{children}</StyledErrorMessage>
)
