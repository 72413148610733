import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFieldArray } from 'react-hook-form'

import { FormattedMessage, useIntl } from 'react-intl'
import { getTelemetryAssetSourceId } from '@/feature/telemetry/utils'

import { RequestStatus } from '@/lib/core/interfaces/common'
import { PlusCircleIcon } from '@/lib/core/components/Icon'
import { SelectOption } from '@/lib/core/components/Select'
import { Label } from '@/lib/core/components/TextField/TextField.styles'
import { TooltipKeyValue, TooltipKeyValueProps } from '@/lib/core/components/TooltipKeyValue'

import { getSearchAssetsSelector } from '@/lib/features/Assets/store'
import { Asset } from '@/lib/features/Assets/interfaces'
import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import {
  AssetRowFormState,
} from '@/lib/features/SmartShares/components/ShareAssetModal'
import { ShareRow } from '@/lib/features/SmartShares/components/ShareAssetModal/components'
import {
  AddAssetButton,
  Footer,
  Table,
  Th,
  ThAsset,
  ThRemove,
  Wrapper,
} from '@/lib/features/SmartShares/components/ShareAssetModal/components/AssetsTable'
import { Share } from '@/lib/features/SmartShares/interfaces'

export const getOptionLabel = (asset: Partial<Asset> | null) => {
  if (!asset) {
    return ''
  }

  const { description, model, plateNumber } = asset

  return [description, plateNumber, model].filter(Boolean).join(' | ')
}

export type AssetsTableProps = {
  assetDefaultValues: AssetRowFormState
  onAddNewAsset?: () => void
  shareToExtend?: Share
}

export const AssetsTable = ({
  assetDefaultValues,
  onAddNewAsset,
  shareToExtend,
}: AssetsTableProps) => {
  const { formatMessage } = useIntl()
  const assetsList = useSelector(getSearchAssetsSelector)

  const getLabel = useCallback(getOptionLabel, [assetsList])

  const items = useMemo(
    () =>
      assetsList.data.results.map(
        (asset) =>
          ({
            label: getLabel(asset),
            value: asset.id,
            data: {
              asset,
              id: getTelemetryAssetSourceId(asset),
            },
          }) as SelectOption,
      ),
    [assetsList],
  )

  const durationTooltip = useMemo<TooltipKeyValueProps['content']>(
    () => [
      {
        key: `${formatMessage(messages.durationInstant)}:`,
        value: formatMessage(messages.durationInstantDescription),
      },
      {
        key: `${formatMessage(messages.durationCustom)}:`,
        value: formatMessage(messages.durationCustomDescription),
      },
    ],
    [],
  )

  const { append, fields, remove } = useFieldArray({ name: 'assets' })
  const onRowRemove = (index: number) => remove(index)

  return (
    <Wrapper>
      <Label>
        <FormattedMessage {...messages.assetSelectLabel} />
      </Label>
      <Table>
        <thead>
          <tr>
            <ThAsset>
              <FormattedMessage {...messages.assetName} />
            </ThAsset>
            <Th>Reference No</Th>
            <Th>
              <TooltipKeyValue content={durationTooltip}>
                <FormattedMessage {...messages.duration} />
              </TooltipKeyValue>
            </Th>
            <Th />
            <ThRemove>
              <FormattedMessage {...messages.remove} />
            </ThRemove>
            {/* <ThCost> */}
            {/*  <FormattedMessage {...messages.cost} /> */}
            {/* </ThCost> */}
          </tr>
        </thead>
        <tbody>
          {fields.map(({ id }, index) => (
            <ShareRow
              key={id}
              items={items}
              index={index}
              isLoading={assetsList.status !== RequestStatus.SUCCEEDED}
              onAddNewAsset={onAddNewAsset}
              shareToExtend={shareToExtend}
              onRemove={() => onRowRemove(index)}
              rows={fields.length}
              uid={id}
            />
          ))}
        </tbody>
      </Table>
      <Footer>
        <AddAssetButton
          disabled={!!shareToExtend}
          type="button"
          onClick={() => append({ ...assetDefaultValues })}
        >
          <PlusCircleIcon size="24" />
          <FormattedMessage {...messages.addAnother} tagName="span" />
        </AddAssetButton>
      </Footer>
    </Wrapper>
  )
}
