import { defineMessages } from 'react-intl'

export default defineMessages({
  addAnother: {
    defaultMessage: 'Add another',
    description: 'share assets form | add another button label',
  },
  assetName: {
    defaultMessage: 'Asset name',
    description: 'share assets form | asset name table column',
  },
  assetSelectAddNew: {
    defaultMessage: 'Add New Asset',
    description: 'share assets form | asset select add new option label',
  },
  assetSelectLabel: {
    defaultMessage: 'Select Assets to Share *',
    description: 'share assets form | select assets field label',
  },
  assetSelectPlaceholder: {
    defaultMessage: 'Please Select',
    description: 'share assets form | asset select placeholder',
  },
  cost: {
    defaultMessage: 'Cost',
    description: 'share assets form | cost table column, total cost text',
  },
  datesRequiredValidation: {
    defaultMessage: 'Start and end date fields are required',
    description: 'share assets form | date from and to required validation error',
  },
  duration: {
    defaultMessage: 'Duration',
    description: 'share assets form | duration table column',
  },
  durationCustom: {
    defaultMessage: 'Custom',
    description: 'share assets form | duration custom option label and tooltip key',
  },
  durationCustomDescription: {
    defaultMessage:
      'Select a Share duration that starts at 00:00 of the start day until 23:59 of the end day.',
    description: 'share assets form | duration custom instant label',
  },
  durationInstant: {
    defaultMessage: 'Instant',
    description: 'share assets form | duration custom instant label and tooltip key',
  },
  durationInstantDescription: {
    defaultMessage: 'Vehicle location data will be Shared immediately, until 23:59 tonight.',
    description: 'share assets form | duration custom instant label',
  },
  formGeneralError: {
    defaultMessage: 'Adjust form fields and try again',
    description: 'share assets form | general error above the form',
  },
  loading: {
    defaultMessage: 'Loading...',
    description: 'share assets form | loading assets text',
  },
  organizationToAddNew: {
    defaultMessage: 'Add a New Contact',
    description: 'share assets form | target organization add new option label',
  },
  organizationToLabel: {
    defaultMessage: 'Who are you sharing with',
    description: 'share assets form | target organization field label',
  },
  organizationToPlaceholder: {
    defaultMessage: 'Please Select a Contact',
    description: 'share assets form | target organization field placeholder',
  },
  organizationContactToLabel: {
    defaultMessage: 'Select contact for ...',
    description: 'share assets modal | target organization contact field label',
  },
  organizationContactToPlaceholder: {
    defaultMessage: 'Please Select a Contact',
    description: 'share assets form | target organization field placeholder',
  },
  organizationContactFromLabel: {
    defaultMessage: 'Select your contact for this share',
    description: 'share assets modal | target organization contact field label',
  },
  organizationContactFromPlaceholder: {
    defaultMessage: 'Please Select a Contact',
    description: 'share assets form | target organization field placeholder',
  },
  remove: {
    defaultMessage: 'Remove',
    description: 'share assets form | remove table column',
  },
  sendButton: {
    defaultMessage: 'Next',
    description: 'share assets form | form send button label',
  },
  sharedWith: {
    defaultMessage: 'with',
    description: 'assets shared successfully modal | with <organization>',
  },
  subtitle: {
    defaultMessage: 'Create or manage a Smart Share',
    description: 'share assets form | modal subtitle',
  },
  contactSelectSubtitle: {
    defaultMessage: 'Select contact details for your Smart Share. These fields are optional.',
    description: 'share contact select form | modal subtitle',
  },
  extendShareSubtitle: {
    defaultMessage: 'Extend Smart Share',
    description: 'share assets form | modal subtitle',
  },
  summaryModalTitle: {
    defaultMessage: 'Assets shared successfully',
    description: 'assets shared successfully modal | title',
  },
  extendModalTitle: {
    defaultMessage: 'Extend (Step 1/2)',
    description: 'extend assets form | modal title',
  },
  contactSelectModalTitle: {
    defaultMessage: '',
    description: 'extend assets form | modal title',
  },
  extendContactSelectModalTitle: {
    defaultMessage: '',
    description: 'extend assets form | modal title',
  },
  title: {
    defaultMessage: 'Share your Assets (Step 1/2)',
    description: 'share assets form | modal title',
  },
  to: {
    defaultMessage: 'To',
    description: 'share assets form | to date text',
  },
})
