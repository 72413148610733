import styled from 'styled-components'

export const Container = styled.div`
  width: 287px;
`

export const ExtendSelectContactLabel = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  padding-bottom: 8px;
`
export const ExtendSelectContactItem = styled.div`
  margin-bottom: 40px;
  color: #dddddd;
  //border: 1px solid #9dd0e2;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 16px;
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
`
