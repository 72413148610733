import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'

import { FormattedMessage } from 'react-intl'

import { FeatureModal } from '@/lib/core/components/Modal'
import { MergeIcon } from '@/lib/core/components/Icon'
import { Button } from '@/lib/core/components/Button'
import { SelectOption } from '@/lib/core/components/Select'

import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import { ContactFormState, ShareFormState } from '@/lib/features/SmartShares/components'
import { SelectContactTo } from '@/lib/features/SmartShares/components/ShareAssetModal/components/SelectContactFor'
import { SelectContactFrom } from '@/lib/features/SmartShares/components/ShareAssetModal/components/SelectContactFrom'
import { Share } from '@/lib/features/SmartShares/interfaces'
import { ErrorBox } from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'

import { FeatureTypes } from '@/lib/utils/features'

import { Actions, Container } from './ContactSelectModal.styles'

export type ContactSelectModalProps = {
  isActive: boolean
  shareToExtend?: Share
  onClose?: (withSuccess?: boolean) => void
  shareAssetSubmit: (values: ShareFormState) => Promise<void>
  shareAssetValues?: ShareFormState
  setShowContactSelectModal: Dispatch<SetStateAction<boolean>>
  generalError: string
  formMethods: UseFormReturn<ShareFormState, unknown>
}

export const ContactSelectModal = ({
  isActive,
  shareToExtend,
  onClose,
  shareAssetSubmit,
  shareAssetValues,
  setShowContactSelectModal,
  generalError,
  formMethods,
}: ContactSelectModalProps) => {
  const [disableSubmit, setDisableSubmit] = useState(false)

  const { formState, getValues, handleSubmit, reset, setValue, watch } = formMethods
  const { isSubmitted, isSubmitting, isValid } = formState

  const currentContactTo = watch('contactTo')
  const currentContactFrom = watch('contactFrom')

  const contactToDefaultValue = useMemo(() => {
    if (shareToExtend?.contactTo) {
      const { id, firstName, lastName } = shareToExtend.contactTo
      return { label: `${firstName} ${lastName}`, value: id } as SelectOption
    }
  }, [shareToExtend, currentContactTo])

  const contactFromDefaultValue = useMemo(() => {
    if (shareToExtend?.contactFrom) {
      const { id, firstName, lastName } = shareToExtend.contactFrom
      return { label: `${firstName} ${lastName}`, value: id } as SelectOption
    }
  }, [shareToExtend])

  useEffect(() => {
    const to = getValues('contactTo') != undefined
    const from = getValues('contactFrom') !== null

    if (to && from) return setDisableSubmit(false)
    if (to || from) return setDisableSubmit(true)
  }, [currentContactTo, currentContactFrom])

  const onSubmit = async (values: ContactFormState) => {
    if (shareAssetValues) {
      const payload = {
        organizationTo: shareAssetValues.organizationTo,
        assets: shareAssetValues.assets.map((asset) => {
          return {
            ...asset,
            contactTo: values.contactTo,
            contactFrom: values.contactFrom,
          }
        }),
      } as ShareFormState

      await shareAssetSubmit(payload)
    }
  }

  useEffect(() => {
    if (shareToExtend?.contactTo && shareToExtend?.contactFrom) {
      setValue('contactTo', shareToExtend.contactTo.id)
      setValue('contactFrom', shareToExtend.contactFrom.id)
    }
  }, [shareToExtend])

  return (
    <FeatureModal
      isActive={isActive}
      feature={FeatureTypes.SHARES}
      title={shareToExtend ? 'Extend (Step 2/2)' : 'Share your Assets (Step 2/2)'}
      titleIcon={<MergeIcon width="48" height="48" />}
      subtitle={<FormattedMessage {...messages.contactSelectSubtitle} />}
      onClose={() => {
        reset()
        setShowContactSelectModal(false)

        if (onClose) {
          onClose()
        }
      }}
    >
      <Container>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {isSubmitted && !isValid && (
            <ErrorBox>
              {generalError || <FormattedMessage {...messages.formGeneralError} />}
            </ErrorBox>
          )}
          <SelectContactTo
            shareAssetValues={shareAssetValues}
            defaultValue={contactToDefaultValue}
          />
          <SelectContactFrom defaultValue={contactFromDefaultValue} />
          <Actions>
            <Button
              type="button"
              isLoading={isSubmitting}
              variant="ghost"
              fitToContent
              size="large"
              minWidth
              onClick={() => setShowContactSelectModal(false)}
            >
              Previous
            </Button>
            <Button
              disabled={disableSubmit}
              type="submit"
              isLoading={isSubmitting}
              variant="secondary"
              fitToContent
              size="large"
              minWidth
            >
              Share Assets
            </Button>
          </Actions>
        </form>
      </Container>
    </FeatureModal>
  )
}
