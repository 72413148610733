import { useMemo } from 'react'

import { useIntl } from 'react-intl'

export type NumberFormatOptions = Omit<Intl.NumberFormatOptions, 'currencyDisplay'> & {
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name'
}

export enum CurrencyLocaleType {
  APP_LANG = 'appLang',
  BROWSER = 'browser',
  RSA = 'RSA',
}

export const useCurrency = (
  localeType = CurrencyLocaleType.BROWSER,
  forceLocale?: string | Array<string>,
) => {
  const { locale } = useIntl()
  const formatLocale = useMemo(
    () =>
      ({
        [CurrencyLocaleType.APP_LANG]: locale,
        [CurrencyLocaleType.BROWSER]: window.navigator.language,
        [CurrencyLocaleType.RSA]: 'af-ZA',
      })[localeType],
    [localeType, locale],
  )

  return {
    formatAmount: (
      value: number,
      currency = 'ZAR',
      extraOptions: Partial<NumberFormatOptions> = {},
    ) => {
      const options: NumberFormatOptions = {
        currency,
        minimumFractionDigits: 0,
        notation: 'compact',
        style: 'currency',
        unitDisplay: 'short',
        ...extraOptions,
      }

      return new Intl.NumberFormat(forceLocale || formatLocale, options).format(value)
    },
  }
}
