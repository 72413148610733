import styled, { css } from 'styled-components'

import { Label } from '@/lib/core/components/TextField/TextField.styles'

export const Container = styled.div`
  width: 100%;
  min-height: 580px;
  padding: ${({ theme: { layout } }) => `${layout.spacing(5)} ${layout.spacing(6)}`};

  ${Label} {
    font-weight: 600;
    font-size: 1.125rem;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  min-height: ${({ theme: { layout } }) => layout.spacing(11)};
  margin-top: ${({ theme: { layout } }) => layout.spacing(2)};
`

export type SelectAdormentProps = {
  $disabled?: boolean
}

export const SelectAdorment = styled.span<SelectAdormentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 1px solid ${({ theme: { colors } }) => colors.border};
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: #dddddd;
    `}
`

export const SharedWith = styled.span`
  font-style: italic;
`

export const OrganizationName = styled.span`
  font-style: normal;
`

export const RequiredFieldsLabel = styled.p`
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 230.6%;
  color: #91979d;
  margin-top: 0;
`
