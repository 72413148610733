import styled, { css } from 'styled-components'

import { ErrorMessage } from '@/lib/core/components/ErrorMessage'

export const Wrapper = styled.div`
  margin-top: ${({ theme: { layout } }) => layout.spacing(2)};
`

export const Table = styled.table`
  width: 100%;
  margin: ${({ theme: { layout } }) => `${layout.spacing(1)} 0`};
  border-spacing: 0;
`

type CellProps = {
  $center?: boolean
  $middle?: boolean
}

const cellStyles = css<CellProps>`
  padding: ${({ theme: { layout } }) => layout.spacing(1)};
  vertical-align: top;
  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}
  ${({ $middle }) =>
    $middle &&
    css`
      vertical-align: middle;
    `}

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

export const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.textGray};
  font-weight: 400;
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  text-align: left;
  text-transform: uppercase;
  ${cellStyles}
`

export const ThAsset = styled(Th)`
  width: 300px;
`

export const ThRemove = styled(Th)`
  width: ${({ theme: { layout } }) => layout.spacing(9)};
  text-align: center;
`

export const ThCost = styled(Th)`
  width: ${({ theme: { layout } }) => layout.spacing(6)};
  text-align: center;
`

export const Td = styled.td`
  ${cellStyles}
`

export const DurationTypeTd = styled(Td)`
  width: 0; /* content determines the width of the cell */
  padding-right: 0;
`

export const CellCost = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme: { layout } }) => layout.spacing(7)};
  border-bottom: ${({ theme }) => theme.borderBase};
`

export const CellDuration = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const Error = styled(ErrorMessage)`
  margin: 0;
`

export const Remove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ theme: { layout } }) => layout.spacing(7)};
  border: none;
  background: none;
  color: ${({ theme: { colors } }) => colors.active};
  cursor: pointer;

  &:disabled {
    color: ${({ theme: { colors } }) => colors.disabled};
    cursor: default;
  }

  svg {
    display: block;
    transform: rotateZ(45deg);
  }
`

export const CostValue = styled.span`
  font-weight: 700;
  font-size: ${({ theme: { fontSize } }) => fontSize.default};
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { layout } }) => `${layout.spacing(2)} 0`};
`

export const AddAssetButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  border: none;
  background: none;
  color: ${({ theme: { colors } }) => colors.active};
  font-size: ${({ theme: { fontSize } }) => fontSize.default};
  line-height: 1;
  cursor: pointer;

  svg {
    display: block;
  }

  span {
    padding-top: 1px;
  }

  :disabled {
    color: #dddddd;
  }
`

export const TotalCost = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  margin: 0;
  padding-right: ${({ theme: { layout } }) => layout.spacing(1)};
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  text-transform: uppercase;
`
