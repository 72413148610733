import { ErrorMessageComponent } from './ErrorMessage'
import { StyledErrorMessage } from './ErrorMessage.styles'

export type { ErrorMessageProps } from './ErrorMessage'

export const ErrorMessage = Object.assign(ErrorMessageComponent, {
  Styled: {
    StyledErrorMessage,
  },
})
