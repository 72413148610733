import { defineMessages } from 'react-intl'

export default defineMessages({
  assetId: {
    defaultMessage: 'Asset ID',
    description: 'assets shared successfully modal | asset id table colum header',
  },
  cost: {
    defaultMessage: 'Cost',
    description: 'assets shared successfully modal | const table column header',
  },
  reference: {
    defaultMessage: 'Reference',
    description: 'assets shared successfully modal | reference table column header',
  },
  dates: {
    defaultMessage: 'Dates',
    description: 'assets shared successfully modal | dates table column header',
  },
  days: {
    defaultMessage: 'Days',
    description: 'assets shared successfully modal | days table column header',
  },
  description: {
    defaultMessage: 'Description',
    description: 'assets shared successfully modal | description column header',
  },
  done: {
    defaultMessage: 'Done',
    description: 'assets shared successfully modal | close button',
  },
  totalCost: {
    defaultMessage: 'Total',
    description: 'assets shared successfully modal | total cost',
  },
})
