import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { useIntl } from 'react-intl'

import { GET_ALL_LIMIT } from '@/lib/core/constants/constants'
import { useAppDispatch } from '@/lib/core/store/store'
import { SelectField, SelectOption } from '@/lib/core/components/Select'

import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import { getMyColleagues, getMyColleaguesSelector } from '@/lib/features/Account/store'

import { Container } from './SelectContactFrom.styles'

export type SelectContactFromProps = {
  defaultValue?: SelectOption
}

export const SelectContactFrom = ({ defaultValue }: SelectContactFromProps) => {
  const { formatMessage } = useIntl()
  const { formState } = useFormContext()
  const dispatch = useAppDispatch()

  const fetchMyColleagues = () => dispatch(getMyColleagues({ pageSize: GET_ALL_LIMIT }))
  const { data } = useSelector(getMyColleaguesSelector)

  const options = useMemo(
    () =>
      data.results
        .filter(({ user: { shareContact } }) => shareContact)
        .sort((a, b) => {
          const aFullName = `${a.user.firstName} ${a.user.lastName}`.toLowerCase()
          const bFullName = `${b.user.firstName} ${b.user.lastName}`.toLowerCase()
          let res = aFullName.localeCompare(bFullName, 'en', { caseFirst: 'upper' })
          if (res === 0) {
            res = a.user.id - b.user.id
          }
          return res
        })
        .map(
          ({ user: { id, firstName, lastName } }) =>
            ({
              label: `${firstName} ${lastName}`,
              value: id,
            }) as SelectOption,
        ),
    [data],
  )

  useEffect(() => {
    if (data.results.length == 0) {
      fetchMyColleagues()
    }
  }, [data])

  return (
    <Container>
      <SelectField
        aria-labelledby="contactFrom"
        name="contactFrom"
        label={'Select your contact for this share'}
        placeholder={formatMessage(messages.organizationToPlaceholder)}
        disabled={formState.isSubmitting}
        required
        selectProps={{
          defaultValue,
          isDisabled: formState.isSubmitting,
          options,
        }}
      />
    </Container>
  )
}
