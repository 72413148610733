import dayjs from 'dayjs'

import { AssetRowFormState } from './ShareAssetModal'

export const getCost = (dailyCost: number, from?: string | null, to?: string | null) => {
  const diff = from && to ? dayjs(to).diff(from, 'days') : 0
  const cost = dailyCost * (diff + 1)

  return cost
}

export const getTotalCost = (dailyCost: number, assets: Array<AssetRowFormState>) =>
  assets
    .map(({ date }) => getCost(dailyCost, date?.from, date?.to))
    .reduce((sum, cost) => sum + cost, 0)
