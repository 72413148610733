import { ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { InfoIcon } from '@/lib/core/components/Icon'
import { Tooltip } from '@/lib/core/components/Tooltip'

import { Button, Container, Content, Item, Key, Value, Wrapper } from './TooltipKeyValue.styles'

export type TooltipKeyValueProps = {
  children: ReactNode
  content: Array<{
    key: string
    value: string
  }>
}

export const TooltipKeyValue = ({ children, content }: TooltipKeyValueProps) => {
  const { colors } = useTheme()

  return (
    <Wrapper>
      {children}
      <Tooltip
        interactive
        trigger="click"
        arrowOffset={24}
        contentPadding={0}
        placement="top-start"
        tooltipContent={
          <Container>
            <Content>
              {content.map(({ key, value }) => (
                <Item key={`${key}-${value}`}>
                  <Key>{key}</Key>
                  <Value>{value}</Value>
                </Item>
              ))}
            </Content>
          </Container>
        }
      >
        <Button type="button" $variant="primary">
          <InfoIcon color={colors.active} />
        </Button>
      </Tooltip>
    </Wrapper>
  )
}
