import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { useIntl } from 'react-intl'

import { useAppDispatch } from '@/lib/core/store/store'
import { GET_ALL_LIMIT } from '@/lib/core/constants/constants'
import { SelectField, SelectOption } from '@/lib/core/components/Select'

import messages from '@/lib/features/SmartShares/components/ShareAssetModal/ShareAssetModal.messages'
import { getOrganizationsColleagues } from '@/lib/features/Account/store'
import { ShareFormState } from '@/lib/features/SmartShares/components'
import { searchMyContacts, searchMyContactsSelector } from '@/lib/features/Contacts/store'
import { Colleague } from '@/lib/features/Account/interfaces'

import { Container } from './SelectContactFor.styles'

export type SelectContactToProp = {
  shareAssetValues?: ShareFormState
  defaultValue?: SelectOption
}

export const SelectContactTo = ({ shareAssetValues, defaultValue }: SelectContactToProp) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const { formState } = useFormContext()
  const myContacts = useSelector(searchMyContactsSelector)

  const [optionsList, setOptionsList] = useState<Colleague[]>([])

  const options = useMemo(
    () =>
      optionsList
        .filter((option) => option.user.shareContact)
        .sort((a, b) => {
          const aFullName = `${a.user.firstName} ${a.user.lastName}`.toLowerCase()
          const bFullName = `${b.user.firstName} ${b.user.lastName}`.toLowerCase()
          let res = aFullName.localeCompare(bFullName, 'en', { caseFirst: 'upper' })
          if (res === 0) {
            res = a.user.id - b.user.id
          }
          return res
        })
        .map(
          ({ user }) =>
            ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.id,
            }) as SelectOption,
        ),
    [optionsList],
  )

  const organization = useMemo(
    () =>
      myContacts.data.results.find(
        ({ organizationTo: { id } }) => id === Number(shareAssetValues?.organizationTo),
      ),
    [myContacts, shareAssetValues],
  )

  useEffect(() => {
    dispatch(searchMyContacts({ pageSize: GET_ALL_LIMIT }))
    if (shareAssetValues) {
      dispatchOrganizationColleagues()
    }
  }, [])

  const dispatchOrganizationColleagues = async () => {
    if (shareAssetValues) {
      const response = await dispatch(
        getOrganizationsColleagues({
          pageSize: GET_ALL_LIMIT,
          organizationId: shareAssetValues.organizationTo,
        }),
      ).unwrap()
      setOptionsList(response.results)
    }
  }

  return (
    <Container>
      <SelectField
        aria-labelledby="contactTo"
        name="contactTo"
        label={`Select contact for ${organization?.organizationTo.name}`}
        placeholder={formatMessage(messages.organizationToPlaceholder)}
        disabled={formState.isSubmitting}
        required
        selectProps={{
          defaultValue,
          isDisabled: formState.isSubmitting,
          options,
        }}
      />
    </Container>
  )
}
