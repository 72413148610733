import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import get from 'lodash-es/get'

import { FormattedMessage, useIntl } from 'react-intl'

import { formsMessages } from '@/lib/core/messages'
import { useAppDispatch } from '@/lib/core/store/store'
import { DATE_FORMAT, GET_ALL_LIMIT } from '@/lib/core/constants/constants'
import { DateString } from '@/lib/core/interfaces/common'
import { MakeFormState } from '@/lib/core/interfaces/forms'
import { Button } from '@/lib/core/components/Button'
import { MergeIcon } from '@/lib/core/components/Icon'
import { FeatureModal } from '@/lib/core/components/Modal'
import { SelectOption } from '@/lib/core/components/Select'
import { useCurrency } from '@/lib/core/hooks/useCurrency'

import { getUser, getUserSelector } from '@/lib/features/Auth/store'
import { searchAssets } from '@/lib/features/Assets/store'
import { extendShareAsset, shareAssetsToContact } from '@/lib/features/SmartShares/store'
import {
  PostSharesPayload,
  PostSharesResponse,
  PostSharesResponseError,
  Share,
  SinglePostSharesPayload,
} from '@/lib/features/SmartShares/interfaces'
import {
  Actions,
  Container,
  getCost,
  getTotalCost,
  OrganizationName,
  RequiredFieldsLabel,
  SharedWith,
} from '@/lib/features/SmartShares/components/ShareAssetModal'
import {
  AssetsTable,
  SelectContact,
} from '@/lib/features/SmartShares/components/ShareAssetModal/components'
import { SharesSummaryModal } from '@/lib/features/SmartShares/components/SharesSummaryModal'
import { ErrorBox } from '@/lib/features/Assets/components/AddAssetModal/AddAssetModal.styles'
import { ContactSelectModal } from '@/lib/features/SmartShares/components/ContactSelectModal'

import { FeatureTypes } from '@/lib/utils/features'
import { setFormErrors } from '@/lib/utils/forms'

import messages from './ShareAssetModal.messages'

export type AssetRowFormStateInstant = {
  asset: SinglePostSharesPayload['asset'] | null
  contactTo?: number
  contactFrom?: number
  durationType: 'instant'
  reference: string
  ownerId?: number
  date?: null
}

export type AssetRowFormStateCustom = {
  asset: SinglePostSharesPayload['asset'] | null
  contactTo?: number
  contactFrom?: number
  durationType: 'custom'
  reference: string
  ownerId?: number
  date: {
    from: DateString | undefined
    to: DateString | undefined
  }
}

export type AssetRowFormState = AssetRowFormStateInstant | AssetRowFormStateCustom

export type ShareFormState = MakeFormState<
  SinglePostSharesPayload,
  'organizationTo' | 'contactTo' | 'contactFrom'
> & {
  assets: Array<AssetRowFormState>
}

export type ContactFormState = {
  contactTo: number | null | undefined
  contactFrom: number | null | undefined
}

const assetDefaultValues: AssetRowFormState = {
  asset: null,
  durationType: 'instant',
  reference: '',
}

export type ShareAssetModalProps = {
  initialOptions?: Array<SelectOption>
  isActive: boolean
  onAddNewAsset?: () => void
  onAddNewContact?: () => void
  shareToExtend?: Share
  onClose?: (withSuccess?: boolean) => void
}

export const ShareAssetModal = ({
  initialOptions = [],
  isActive,
  onAddNewAsset,
  onAddNewContact,
  onClose,
  shareToExtend,
}: ShareAssetModalProps) => {
  const { formatMessage } = useIntl()
  const { formatAmount } = useCurrency()
  const dispatch = useAppDispatch()
  const user = useSelector(getUserSelector)
  const [addedShare, setAddedShare] = useState<PostSharesResponse | null>(null)
  const [generalError, setGeneralError] = useState('')
  const dailyCost = useMemo(() => parseFloat(user?.organization?.dailyRentCost as string), [user])

  const [shareAssetValues, setShareAssetValues] = useState<ShareFormState>()
  const [showContactSelectModal, setShowContactSelectModal] = useState<boolean>(false)

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        assets: yup.array().of(
          yup.object({
            asset: yup
              .string()
              .nullable()
              .required(formatMessage(formsMessages.requiredValidation)),
            date: yup
              .object({
                from: yup.string().nullable(),
                to: yup.string().nullable(),
              })
              .nullable()
              .when('durationType', {
                is: 'custom',
                then: (schema) => schema.required(formatMessage(messages.datesRequiredValidation)),
              }),
            durationType: yup.string().nullable(),
            reference: yup.string().nullable(),
          }),
        ),
        organizationTo: yup
          .string()
          .nullable()
          .required(formatMessage(formsMessages.requiredValidation)),
        contactTo: yup.number().nullable().optional(),
        contactFrom: yup.number().nullable().optional(),
      }),
    [],
  )

  const formMethods = useForm<ShareFormState>({
    defaultValues: {
      assets: initialOptions.length
        ? initialOptions.map((option) => ({
            ...assetDefaultValues,
            asset: option.value as number,
          }))
        : [{ ...assetDefaultValues }],
      organizationTo: shareToExtend?.organization?.id,
      contactTo: shareToExtend?.contactTo?.id ?? null,
      contactFrom: shareToExtend?.contactFrom?.id ?? null,
    },
    resolver: yupResolver(formSchema),
  })

  const { formState, getValues, handleSubmit, reset, setError, watch } = formMethods

  const { isSubmitted, isSubmitting, isValid } = formState

  const convertToPayload = ({ assets, organizationTo }: ShareFormState): PostSharesPayload =>
    assets.map((rowState) => {
      const { asset, reference, contactTo, contactFrom } = rowState

      // When it's instant from/to are undefined causing dayjs to default to "now"
      const dateFrom = dayjs.max(dayjs(), dayjs(rowState.date?.from))?.format()
      const dateTo = dayjs(rowState.date?.to).endOf('day').format()

      return {
        asset,
        dateFrom,
        dateTo,
        organizationTo,
        reference,
        contactTo,
        contactFrom,
      } as SinglePostSharesPayload
    })

  const convertToExtendPayload = ({ assets, organizationTo }: ShareFormState) =>
    assets.map((rowState) => {
      const { asset, reference, contactTo, contactFrom } = rowState

      // When it's instant from/to are undefined causing dayjs to default to "now"
      const dateFrom = dayjs.max(dayjs(), dayjs(rowState.date?.from))?.format()
      const dateTo = dayjs(rowState.date?.to).endOf('day').format()

      return {
        asset,
        dateFrom,
        dateTo,
        organizationTo,
        reference,
        contactTo,
        contactFrom,
      } as SinglePostSharesPayload
    })

  const onSubmit = async (values: ShareFormState) => {
    setShareAssetValues(values)
    setShowContactSelectModal(true)
  }

  const onShareSubmit = async (values: ShareFormState) => {
    if (Object.keys(formState.errors).length > 0) {
      return
    }
    try {
      if (shareToExtend) {
        const payload = convertToExtendPayload(values)
        const response = await dispatch(
          extendShareAsset({ id: shareToExtend.id, share: payload[0] }),
        ).unwrap()
        setAddedShare([response])
      } else {
        const payload = convertToPayload(values)
        const response = await dispatch(shareAssetsToContact(payload)).unwrap()
        setAddedShare(response)
      }
      setShowContactSelectModal(false)
    } catch (err) {
      const apiErrors = err as PostSharesResponseError
      const generalApiError = get(apiErrors, 'errors[0]', '')
      const organizationToError = get(apiErrors, '0.organizationTo[0]')

      setGeneralError(generalApiError)
      setShowContactSelectModal(false)

      if (organizationToError) {
        setError('organizationTo', {
          message: organizationToError,
          type: 'apiError',
        })
      }

      setFormErrors(
        setError,
        { assets: apiErrors },
        (key: string) =>
          ({
            dateFrom: 'date',
            dateTo: 'date',
          })[key] || key,
      )
    }
  }

  useEffect(() => {
    if (isActive) {
      dispatch(getUser())
      dispatch(searchAssets({ pageSize: GET_ALL_LIMIT }))
    }
  }, [isActive])

  if (showContactSelectModal) {
    return (
      <FormProvider {...formMethods}>
        <ContactSelectModal
          isActive={isActive}
          shareToExtend={shareToExtend}
          onClose={onClose}
          shareAssetSubmit={onShareSubmit}
          shareAssetValues={shareAssetValues}
          setShowContactSelectModal={setShowContactSelectModal}
          generalError={generalError}
          formMethods={formMethods}
        />
      </FormProvider>
    )
  }

  if (addedShare) {
    return (
      <SharesSummaryModal
        isActive={isActive}
        title={<FormattedMessage {...messages.summaryModalTitle} />}
        subtitle={
          <SharedWith>
            <FormattedMessage {...messages.sharedWith} tagName="span" />{' '}
            <OrganizationName>{addedShare[0]?.organizationTo.name}</OrganizationName>
          </SharedWith>
        }
        shares={addedShare.map(({ asset, dateFrom, dateTo, reference }) => {
          const dates = `${dayjs(dateFrom).format(DATE_FORMAT)} - ${dayjs(dateTo).format(DATE_FORMAT)}`
          const days = dayjs(dateTo).diff(dateFrom, 'days') + 1
          const cost = formatAmount(getCost(dailyCost, dateFrom, dateTo))

          return {
            assetId: asset.bridgeId,
            reference,
            cost,
            dates,
            days,
            description: asset.description,
          }
        })}
        totalCost={{
          ZAR: getTotalCost(dailyCost, getValues('assets')),
        }}
        onClose={() => {
          setAddedShare(null)
          setShowContactSelectModal(false)
          reset()

          if (onClose) {
            onClose(true)
          }
        }}
      />
    )
  }

  return (
    <FeatureModal
      isActive={isActive}
      feature={FeatureTypes.SHARES}
      title={formatMessage(shareToExtend ? messages.extendModalTitle : messages.title)}
      titleIcon={<MergeIcon width="48" height="48" />}
      subtitle={formatMessage(shareToExtend ? messages.extendShareSubtitle : messages.subtitle)}
      onClose={() => {
        reset()
        setShowContactSelectModal(false)
        if (onClose) {
          onClose()
        }
      }}
    >
      <Container>
        <FormProvider {...formMethods}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {isSubmitted && !isValid && (
              <ErrorBox>
                {generalError || <FormattedMessage {...messages.formGeneralError} />}
              </ErrorBox>
            )}
            <RequiredFieldsLabel>* Indicates a required field</RequiredFieldsLabel>

            <SelectContact onAddNewContact={onAddNewContact} shareToExtend={shareToExtend} />
            <AssetsTable
              assetDefaultValues={assetDefaultValues}
              shareToExtend={shareToExtend}
              onAddNewAsset={onAddNewAsset}
            />
            <Actions>
              <Button
                type="submit"
                isLoading={isSubmitting}
                variant="secondary"
                fitToContent
                size="large"
                minWidth
                disabled={Object.keys(formState.errors).length > 0 || isSubmitting}
                title={
                  Object.keys(formState.errors).length > 0 ? 'Please correct the form' : undefined
                }
              >
                <FormattedMessage {...messages.sendButton} />
              </Button>
            </Actions>
          </form>
        </FormProvider>
      </Container>
    </FeatureModal>
  )
}
