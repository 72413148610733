import styled from 'styled-components'

import { IconButton } from '@/lib/core/components/Button'

export const Wrapper = styled.span`
  position: relative;
  display: inline-block;
`

export const Button = styled(IconButton)`
  position: absolute;
  bottom: 100%;
  left: 100%;
`

export const Container = styled.span`
  display: block;
  width: ${({ theme: { layout } }) => layout.spacing(40)};
  max-width: 90vw;
  text-transform: none;
`

export const Content = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  padding: ${({ theme: { layout } }) => layout.spacing(3)};
`

export const Item = styled.span`
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  line-height: 1.5;
`

export const Key = styled.span`
  display: inline-block;
  margin-right: 0.6em;
  color: #fff;
`

export const Value = styled.span`
  color: ${({ theme: { colors } }) => colors.textHighlighted};
`
